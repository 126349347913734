<template>
  <v-container class="mt-n5">
    <PageHeader :title="pageTitle" :path="goToPath" />

    <!-- filtros -->
    <v-card class="mt-n5" :loading="!loadedView">
      <v-container
        class="pt-0"
        :class="{ 'pb-0': !showFilters, 'pb-4': showFilters }"
      >
        <v-card-actions>
          <v-row align="start" no-gutters>
            <v-col
              cols="11"
              v-if="filtersApplied && filtersApplied.length > 0 && !showFilters"
            >
              <v-chip
                v-for="(filter, key) in filtersApplied"
                :key="key"
                class="mr-2"
                label
                outlined
                color="primary"
                @click="showFilters = true"
              >
                {{
                  `${filter.label}: ${
                    Array.isArray(filter.model)
                      ? truncateText(getTextWhenFilterIsArray(filter))
                      : truncateText(filter.model)
                  }`
                }}
              </v-chip>
            </v-col>
            <v-col
              cols="11"
              v-if="
                !(filtersApplied && filtersApplied.length > 0 && !showFilters)
              "
            ></v-col>
            <v-col xl="1" lg="1" md="1" sm="1" xs="1">
              <v-tooltip location="right">
                <template v-slot:activator="{ props }">
                  <v-btn
                    :icon="showFilters ? upArrowIcon : downArrowIcon"
                    @click="showFilters = !showFilters"
                    color="grey"
                    v-bind="props"
                    class="mr-5 white--text to-right"
                    size="25px"
                    id="v-btn-showFilters"
                  ></v-btn>
                </template>
                {{
                  showFilters ? "Ocultar filtros" : "Mostrar filtros"
                }}</v-tooltip
              >
            </v-col>
          </v-row>
        </v-card-actions>
        <v-expand-transition v-if="loadedView">
          <v-form
            v-show="showFilters"
            v-model="isFormValid"
            ref="filters-form"
            id="filters-form"
            @submit.prevent="loadSolicitudes()"
          >
            <v-row :no-gutters="isMobile">
              <v-col
                cols="12"
                xl="2"
                :lg="3"
                :md="isUserFarmacia ? 4 : 6"
                :sm="6"
                xs="12"
                class="pb-0"
              >
                <v-text-field
                  label="Número solicitud"
                  v-model="numeroSolicitud"
                  autocomplete="solicitud"
                  clearable
                  variant="outlined"
                  density="compact"
                  onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                  :rules="[
                    rules.maxLength(numeroSolicitud, 100),
                    rules.numeric,
                  ]"
                  @update:model-value="changeNumeroSolicitud"
                  @keydown.tab="
                    numeroSolicitud
                      ? focusComponent('vBtnAplicarFiltros', 1)
                      : null
                  "
                  id="v-text-field-nroSolicitud"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                xl="4"
                :lg="3"
                :md="isUserFarmacia ? 4 : 6"
                :sm="6"
                xs="12"
                class="pb-0"
              >
                <v-autocomplete
                  label="Afiliado"
                  v-model="selectedAfiliado"
                  autocomplete="off"
                  :items="afiliados"
                  variant="outlined"
                  density="compact"
                  v-model:search="inputAfiliado"
                  return-object
                  item-title="value3"
                  item-value="value1"
                  hint="Búsqueda por DNI o N° afiliado"
                  persistent-hint
                  :custom-filter="customFilter"
                  :loading="loadingAfiliados"
                  clearable
                  :no-data-text="afiliadosNoDataText"
                  :disabled="disabledByNroSolicitud"
                  :readonly="selectedAfiliado != null"
                  :menu-props="{ closeOnContentClick: true }"
                  id="v-autocomplete-afiliado"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item @click="setAfiliado(item)">
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                      <v-list-item-subtitle
                        >N° afiliado: {{ item.value }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        >DNI: {{ item.raw.value2 }}
                      </v-list-item-subtitle>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                xl="3"
                :lg="3"
                :md="isUserFarmacia ? 4 : 6"
                :sm="6"
                xs="12"
                class="pb-0"
                v-if="!isUserFarmacia"
              >
                <v-autocomplete
                  label="Financiador"
                  v-model="selectedFinanciador"
                  autocomplete="off"
                  :items="financiadores"
                  :disabled="disabledByNroSolicitud"
                  variant="outlined"
                  density="compact"
                  return-object
                  item-value="id"
                  item-title="value"
                  :clearable="isClearable('financiador')"
                  :loading="financiadores.length === 0"
                  :readonly="isUserFinanciador"
                  id="v-autocomplete-financiador"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                xl="3"
                :lg="3"
                :md="isUserFarmacia ? 4 : 6"
                :sm="6"
                xs="12"
                class="pb-0"
                v-if="!isUserFarmacia && !isUserPrestador"
              >
                <v-autocomplete
                  variant="outlined"
                  density="compact"
                  label="Emisores"
                  autocomplete="off"
                  v-model="selectedEmisor"
                  :disabled="disabledByNroSolicitud"
                  :items="emisores"
                  return-object
                  item-value="id"
                  item-title="value"
                  :clearable="isClearable('emisor')"
                  :loading="emisores.length === 0"
                  :readonly="isUserEmisor"
                  id="v-autocomplete-emisor"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                xl="3"
                :lg="isUserFarmacia ? 3 : 3"
                :md="isUserFarmacia ? 4 : 6"
                :sm="isUserFarmacia ? 6 : 6"
                xs="12"
                class="pb-0"
                v-if="!isUserFarmacia && !isUserPrestador"
              >
                <v-autocomplete
                  label="Estado/s"
                  v-model="selectedEstados"
                  autocomplete="off"
                  :items="estadosList"
                  :disabled="disabledByNroSolicitud"
                  variant="outlined"
                  density="compact"
                  :loading="estadosList.length === 0"
                  multiple
                  return-object
                  item-value="id"
                  item-title="value"
                  clearable
                  id="v-autocomplete-estadosList"
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index < 1">
                      <span>{{ item.title }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ selectedEstados.length - 1 }} otros)
                    </span>
                  </template></v-autocomplete
                >
              </v-col>
              <!-- fecha desde -->
              <v-col
                cols="12"
                xl="2"
                :lg="isUserFarmacia ? 3 : 3"
                :md="isUserFarmacia ? 4 : 6"
                :sm="isUserFarmacia ? 6 : 6"
                xs="12"
                class="pb-0"
              >
                <DatePicker
                  @selectFecha="selectFechaDesde($event)"
                  label="Fecha prescripción desde"
                  :minDate="minDate"
                  :disabled="disabledByNroSolicitud"
                  :clearable="true"
                  :clearFecha="isClearFechas"
                  :dateMenu="menuFechaDesde"
                  :defaultDate="fechaDesde"
                  :rules="
                    numeroSolicitud ||
                    selectedFechaDispensaDesde ||
                    selectedFechaDispensaHasta
                      ? []
                      : [
                          ...rules.required,
                          ...rules.validDateFormat,
                          rules.validDateRange(
                            selectedFechaDesde,
                            selectedFechaHasta
                          ),
                        ]
                  "
                />
              </v-col>
              <!-- fecha hasta -->
              <v-col
                cols="12"
                xl="2"
                :lg="isUserFarmacia ? 3 : 3"
                :md="isUserFarmacia ? 4 : 6"
                :sm="isUserFarmacia ? 6 : 6"
                xs="12"
                class="pb-0"
              >
                <DatePicker
                  @selectFecha="selectFechaHasta($event)"
                  label="Fecha prescripción hasta"
                  :minDate="minDate"
                  :disabled="disabledByNroSolicitud"
                  :clearable="true"
                  :clearFecha="isClearFechas"
                  :dateMenu="menuFechaHasta"
                  :defaultDate="fechaHasta"
                  :rules="
                    numeroSolicitud ||
                    selectedFechaDispensaDesde ||
                    selectedFechaDispensaHasta
                      ? []
                      : [
                          ...rules.required,
                          ...rules.validDateFormat,
                          rules.validDateRange(
                            selectedFechaDesde,
                            selectedFechaHasta
                          ),
                        ]
                  "
                />
              </v-col>
              <!-- fecha dispensa desde -->
              <v-col
                v-if="isUserFarmacia"
                cols="12"
                xl="2"
                :lg="isUserFarmacia ? 3 : 3"
                :md="isUserFarmacia ? 4 : 6"
                :sm="isUserFarmacia ? 6 : 6"
                xs="12"
                class="pb-0"
              >
                <DatePicker
                  @selectFecha="selectFechaDispensaDesde($event)"
                  label="Fecha dispensa desde"
                  :minDate="minDate"
                  :clearable="true"
                  :disabled="disabledByNroSolicitud"
                  :clearFecha="isClearFechasDispensa"
                  :dateMenu="menuFechaDispensaDesde"
                  :defaultDate="fechaDispensaDesde"
                  :rules="
                    numeroSolicitud == null &&
                    selectedFechaDesde == null &&
                    selectedFechaHasta == null &&
                    isUserFarmacia
                      ? [
                          ...rules.required,
                          ...rules.validDateFormat,
                          rules.validDateRange(
                            selectedFechaDispensaDesde,
                            selectedFechaDispensaHasta
                          ),
                        ]
                      : []
                  "
                />
              </v-col>
              <!-- fecha dispensa hasta -->
              <v-col
                v-if="isUserFarmacia"
                cols="12"
                xl="2"
                :lg="isUserFarmacia ? 3 : 3"
                :md="isUserFarmacia ? 4 : 6"
                :sm="isUserFarmacia ? 6 : 6"
                xs="12"
                class="pb-0"
              >
                <DatePicker
                  @selectFecha="selectFechaDispensaHasta($event)"
                  label="Fecha dispensa hasta"
                  :minDate="minDate"
                  :disabled="disabledByNroSolicitud"
                  :clearable="true"
                  :clearFecha="isClearFechasDispensa"
                  :dateMenu="menuFechaDispensaHasta"
                  :defaultDate="fechaDispensaHasta"
                  :rules="
                    numeroSolicitud == null &&
                    selectedFechaDesde == null &&
                    selectedFechaHasta == null &&
                    isUserFarmacia
                      ? [
                          ...rules.required,
                          ...rules.validDateFormat,
                          rules.validDateRange(
                            selectedFechaDispensaDesde,
                            selectedFechaDispensaHasta
                          ),
                        ]
                      : []
                  "
                />
              </v-col>
            </v-row>
            <v-row class="text-right py-0" no-gutters>
              <v-col cols="12">
                <v-tooltip location="left">
                  <template v-slot:activator="{ props }">
                    <v-icon
                      color="grey"
                      v-bind="props"
                      class="mr-5 white--text"
                      size="25px"
                      @click="clearFilters"
                      id="v-icon-limpiarFiltros"
                      >{{ clearFiltersIcon }}</v-icon
                    >
                  </template>
                  Limpiar filtros</v-tooltip
                >
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="isAplicarButtonDisabled"
                  form="filters-form"
                  id="vBtnAplicarFiltros"
                >
                  Aplicar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-expand-transition>
      </v-container>
    </v-card>

    <!-- data table -->
    <v-card class="elevation-1 mt-2">
      <v-card-text class="py-0 my-0">
        <v-row no-gutters>
          <v-col cols="12" class="mx-0 px-0">
            <v-data-table
              :headers="headers"
              :items="solicitudes"
              class="pa-3"
              :search="search"
              :loading="isLoadingTable"
              :mobile="isMobile"
              :hide-default-header="isMobile"
              :items-per-page="isMobile ? 5 : 10"
              show-current-page
              :items-per-page-options="
                isMobile
                  ? [
                      { value: 5, title: '5' },
                      { value: 10, title: '10' },
                    ]
                  : [
                      { value: 10, title: '10' },
                      { value: 25, title: '25' },
                      { value: 50, title: '50' },
                      { value: 100, title: '100' },
                      {
                        value: -1,
                        title: '$vuetify.dataFooter.itemsPerPageAll',
                      },
                    ]
              "
            >
              <template v-slot:top>
                <v-toolbar flat style="background-color: transparent">
                  <v-row>
                    <v-col
                      :cols="isMobile ? 8 : 8"
                      align-self="center"
                      class="buscador pl-0 pt-0"
                    >
                      <v-text-field
                        v-model="search"
                        :append-inner-icon="searchIcon"
                        label="Buscar"
                        single-line
                        variant="underlined"
                        hide-details
                        clearable
                        class="ml-5"
                        style="width: auto"
                        id="v-text-field-search"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      :cols="isMobile ? 4 : 4"
                      v-if="
                        permisosSolicitudes.canCreateReceta && isUserPrestador
                      "
                      :style="
                        isMobile
                          ? 'display: flex; flex-wrap: wrap; justify-content: center;'
                          : ''
                      "
                    >
                      <v-menu transition="scale-transition" location="bottom">
                        <template v-slot:activator="{ props }">
                          <v-btn
                            color="primary"
                            v-bind="props"
                            variant="flat"
                            :class="isMobile ? '' : 'to-right'"
                            @click="
                              createOptions.length > 1 &&
                              createOptions.length > 0
                                ? null
                                : openModalCreateSolicitud(createOptions[0].id)
                            "
                          >
                            Nueva
                          </v-btn>
                        </template>
                        <v-list v-if="createOptions.length > 1">
                          <v-list-item
                            v-for="item in createOptions"
                            :key="item.id"
                            link
                            density="compact"
                            @click="openModalCreateSolicitud(item.id)"
                          >
                            <v-list-item-title>{{
                              item.title
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`item.emisor.value`]="{ item }">
                <span>
                  {{
                    this.isUserPrestador
                      ? `${item.nroMatricula} ${item.emisor.value}`
                      : item.emisor.value
                  }}
                </span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <template v-if="isMobile">
                  <v-menu transition="scale-transition" location="left">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        color="primary"
                        v-bind="props"
                        variant="flat"
                        :class="item ? '' : 'to-right'"
                      >
                        Ver acciones
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-if="
                          permisosSolicitudes.canCreateReceta && isUserPrestador
                        "
                        link
                        density="compact"
                        @click="copyReceta(item)"
                      >
                        <v-list-item-title>Copiar receta</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="permisosSolicitudes.canSeeHistory"
                        link
                        density="compact"
                        @click="openModalStatus(item)"
                      >
                        <v-list-item-title
                          >Ver historial estados</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="permisosSolicitudes.canSeeLog"
                        link
                        density="compact"
                        @click="openModalVerLog(item.id)"
                      >
                        <v-list-item-title>Ver log</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="
                          permisosSolicitudes.canSeeDispensarReceta &&
                          canDispensarReceta(item)
                        "
                        link
                        density="compact"
                        @click="openModalReceta(item)"
                      >
                        <v-list-item-title>Dispensar receta</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="
                          (isUserPrestador &&
                            item.estado == 'PENDIENTE DE DISPENSA') ||
                          (isUserEmisor && item.estado != 'ANULADA') ||
                          (permisosSolicitudes.canSeeCancelApplication &&
                            item.estado != 'ANULADA' &&
                            canAnularReceta(item))
                        "
                        link
                        density="compact"
                        @click="openAnularSolicitudDialog(item)"
                      >
                        <v-list-item-title>Anular receta</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="canImprimirReceta(item) || isUserPrestador"
                        link
                        density="compact"
                        @click="imprimirSolicitud(item.numero)"
                      >
                        <v-list-item-title>Imprimir receta</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="
                          !(canImprimirReceta(item) || isUserPrestador) &&
                          !(
                            (isUserPrestador &&
                              item.estado == 'PENDIENTE DE DISPENSA') ||
                            (isUserEmisor && item.estado != 'ANULADA') ||
                            (permisosSolicitudes.canSeeCancelApplication &&
                              item.estado != 'ANULADA' &&
                              canAnularReceta(item))
                          ) &&
                          !(
                            permisosSolicitudes.canSeeDispensarReceta &&
                            canDispensarReceta(item)
                          ) &&
                          !permisosSolicitudes.canSeeLog &&
                          !permisosSolicitudes.canSeeHistory &&
                          !(
                            permisosSolicitudes.canCreateReceta &&
                            isUserPrestador
                          )
                        "
                        link
                        density="compact"
                      >
                        <v-list-item-title
                          >No hay acciones disponibles</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-if="!isMobile">
                  <v-tooltip
                    location="left"
                    v-if="
                      permisosSolicitudes.canCreateReceta && isUserPrestador
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        size="20"
                        :disabled="loadingCopyReceta"
                        id="v-icon-copiar-receta"
                        @click="copyReceta(item)"
                      >
                        {{ copyIcon }}
                      </v-icon>
                    </template>
                    <span>Copiar receta</span>
                  </v-tooltip>
                  <v-tooltip
                    location="left"
                    v-if="permisosSolicitudes.canSeeHistory"
                  >
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        size="20"
                        @keydown.enter="openModalStatus(item)"
                        @click="openModalStatus(item)"
                        id="v-icon-verHistorial"
                      >
                        {{ calendarClockIcon }}
                      </v-icon>
                    </template>
                    <span>Ver historial estados</span>
                  </v-tooltip>
                  <v-tooltip
                    location="left"
                    v-if="permisosSolicitudes.canSeeLog"
                  >
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        size="20"
                        @keydown.enter="openModalVerLog(item.id)"
                        @click="openModalVerLog(item.id)"
                        id="v-icon-verLog"
                      >
                        {{ documentIcon }}
                      </v-icon>
                    </template>
                    <span>Ver log</span>
                  </v-tooltip>
                  <v-tooltip
                    location="left"
                    v-if="
                      permisosSolicitudes.canSeeDispensarReceta &&
                      canDispensarReceta(item)
                    "
                  >
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        size="20"
                        @keydown.enter="openModalReceta(item)"
                        @click="openModalReceta(item)"
                        id="v-icon-dispensarReceta"
                      >
                        {{ editIcon }}
                      </v-icon>
                    </template>
                    <span>Dispensar receta</span>
                  </v-tooltip>
                  <v-tooltip location="left" v-if="canAnularReceta(item)">
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        size="20"
                        @keydown.enter="openAnularSolicitudDialog(item)"
                        @click="openAnularSolicitudDialog(item)"
                        id="v-icon-anularSolicitud"
                      >
                        {{ anularSolicitudIcon }}
                      </v-icon>
                    </template>
                    <span>Anular solicitud</span>
                  </v-tooltip>
                  <v-tooltip
                    location="left"
                    v-if="canImprimirReceta(item)"
                    :loading="loadingPrint"
                  >
                    <template v-slot:activator="{ props }">
                      <v-icon
                        :disabled="loadingPrint"
                        v-bind="props"
                        @keydown.enter="
                          loadingPrint ? null : imprimirSolicitud(item.numero)
                        "
                        size="20"
                        @click="imprimirSolicitud(item.numero)"
                        id="v-icon-imprimirReceta"
                      >
                        {{ imprimirSolicitudIcon }}
                      </v-icon>
                    </template>
                    <span>Imprimir solicitud</span>
                  </v-tooltip>
                </template>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="12" class="pt-5">
        <v-btn
          color="primary"
          class="to-right"
          variant="outlined"
          :disabled="solicitudes.length == 0"
          @click="exportarConsulta()"
        >
          Exportar detalle
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalCreateRecetaElectronica"
      v-model="modalCreateRecetaElectronica"
      :max-width="isMobile ? '100%' : '85%'"
      @keydown.esc="closeDialog(false)"
      persistent
    >
      <CreateRecetaElectronica
        :solicitudItem="solicitudItem"
        @closeModal="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog
      v-if="isOpenModalStatus"
      v-model="isOpenModalStatus"
      :max-width="isMobile ? '95%' : '70%'"
      persistent
      @keydown.esc="closeDialog(false)"
    >
      <estado-solicitud
        :solicitudItem="solicitudItem"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
    <v-dialog
      v-if="verLogSolicitudDialog"
      v-model="verLogSolicitudDialog"
      :max-width="isMobile ? '95%' : '70%'"
      persistent
      @keydown.esc="closeDialog(false)"
    >
      <VerLogSolicitud
        :solicitudId="solicitudItem"
        :esAdmin="esAdmin"
        @closeDialog="closeDialog($event)"
      />
    </v-dialog>
  </v-container>
  <v-dialog
    :max-width="isMobile ? '95%' : '50%'"
    v-model="anularSolicitudDialog"
    persistent
    @keydown.esc="closeDialog(false)"
  >
    <AnularSolicitudes
      :solicitudItem="solicitudItem"
      @anular-cancelado="closeDialog($event)"
    />
  </v-dialog>
  <v-dialog
    v-if="isOpenModalReceta"
    v-model="isOpenModalReceta"
    :max-width="isMobile ? '95%' : '70%'"
    persistent
    @keydown.esc="closeDialog(false)"
  >
    <DispensarReceta
      :solicitudHash="solicitudItem.numero"
      :financiadorId="solicitudItem?.financiador?.id"
      @closeDialog="closeDialog($event)"
    />
  </v-dialog>
</template>

<script>
import PageHeader from "@/components/layout/PageHeader.vue";
import enums from "@/utils/enums/index";
import { mapActions, mapGetters } from "vuex";
import rules from "@/utils/helpers/rules";
import helperDate from "@/utils/helpers/dateTimePicker";
import DatePicker from "@/components/shared/DatePicker.vue";
import EstadoSolicitud from "@/components/modules/solicitudes/EstadoSolicitud.vue";
import AnularSolicitudes from "@/components/modules/solicitudes/AnularSolicitudes.vue";
import DispensarReceta from "@/components/modules/solicitudes/DispensarReceta.vue";
import VerLogSolicitud from "@/components/modules/solicitudes/VerLogSolicitud.vue";
import CreateRecetaElectronica from "@/components/modules/solicitudes/CreateRecetaElectronica.vue";
import { focusComponent } from "@/utils/helpers/htmlHelpers";
import helpersExcel from "@/utils/helpers/importExportExcel.js";

export default {
  name: "SolicitudesView",
  components: {
    PageHeader,
    DatePicker,
    EstadoSolicitud,
    AnularSolicitudes,
    DispensarReceta,
    VerLogSolicitud,
    CreateRecetaElectronica,
  },
  data() {
    return {
      pageTitle: enums.permises.PRESCRIPCIONES.name,
      goToPath: enums.permises.HOME.path,
      isFormValid: false,
      loadingCopyReceta: false,
      search: "",
      emisores: [],
      financiadores: [],
      afiliados: [],
      solicitudes: [],
      selectedEmisor: null,
      selectedFinanciador: null,
      selectedAfiliado: null,
      inputAfiliado: null,
      numeroSolicitud: null,
      searchIcon: enums.icons.SEARCH_ICON,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      anularSolicitudIcon: enums.icons.DOCUMENT_DELETE_ICON,
      imprimirSolicitudIcon: enums.icons.PRINT_ICON,
      showFilters: true,
      headers: [
        {
          title: "Número de solicitud",
          key: "numero",
          sortable: false,
        },
        {
          title: "Fecha creación",
          key: "fechaCreacion",
          sortable: false,
        },
        {
          title: "Fecha prescripción",
          key: "fechaEmision",
          sortable: false,
        },
        {
          title: "Afiliado",
          key: "apeNomAfi",
          sortable: false,
        },
        {
          title: "Emisor",
          key: "emisor.value",
          sortable: false,
        },
        {
          title: "Financiador",
          key: "financiador.value",
          sortable: false,
        },
        {
          title: "Estado",
          key: "estado",
          sortable: false,
        },
        {
          title: "Acciones",
          key: "actions",
          align: "end",
          sortable: false,
        },
      ],
      isLoadingTable: false,
      calendarClockIcon: enums.icons.CALENDAR_CLOCK,
      documentIcon: enums.icons.DOCUMENT,
      filtersApplied: [],
      loadingAfiliados: false,
      rules: rules,
      menuFechaDesde: false,
      fechaDesde: null,
      selectedFechaDesde: null,
      menuFechaHasta: false,
      fechaHasta: null,
      selectedFechaHasta: null,
      selectedFechaDispensaDesde: null,
      fechaDispensaDesde: null,
      menuFechaDispensaDesde: false,
      selectedFechaDispensaHasta: null,
      fechaDispensaHasta: null,
      menuFechaDispensaHasta: false,
      calendarIcon: enums.icons.CALENDAR,
      disabledByNroSolicitud: false,
      minDate: "2001",
      isUserFinanciador: false,
      isUserEmisor: false,
      downArrowIcon: enums.icons.DOWN_ARROW_ICON,
      upArrowIcon: enums.icons.UP_ARROW_ICON,
      editIcon: enums.icons.DOCUMENT_EDIT_ICON,
      afiliadosNoDataText: "",
      isOpenModalStatus: false,
      isOpenModalReceta: false,
      anularSolicitudDialog: false,
      verLogSolicitudDialog: false,
      solicitudItem: null,
      isUserFarmacia: false,
      selectedEstados: [],
      estadosList: [],
      createOptions: [],
      modalCreateRecetaElectronica: false,
      loadingPrint: false,
      timerAfiliado: null,
      isUserPrestador: false,
      loadedView: false,
      copyIcon: enums.icons.COPY_ICON,
      esAdmin: false,
    };
  },
  computed: {
    isClearFechas() {
      return (
        this.selectedFechaDispensaDesde !== null &&
        this.selectedFechaDispensaHasta !== null
      );
    },
    isClearFechasDispensa() {
      return (
        this.selectedFechaDesde !== null && this.selectedFechaHasta !== null
      );
    },
    isAplicarButtonDisabled() {
      return (
        (!this.selectedEmisor &&
          !this.selectedFinanciador &&
          !this.selectedAfiliado &&
          !this.numeroSolicitud) ||
        !this.isFormValid
      );
    },
    ...mapGetters({
      permisos: "menu/permisos",
      isMobile: "menu/isMobile",
    }),
    permisosSolicitudes() {
      let canSeeHistory = false;
      let canSeeLog = false;
      let canSeeCancelApplication = false;
      let canSeeDispensarReceta = false;
      let canCreateReceta = false;

      if (this.permisos != null) {
        this.permisos.forEach((x) => {
          switch (x.permisoCodigo) {
            case enums.permises.VER_HISTORIAL.code:
              canSeeHistory = true;
              break;
            case enums.permises.DISPENSAR_RECETA.code:
              canSeeDispensarReceta = true;
              break;
            case enums.permises.VER_LOG.code:
              canSeeLog = true;
              break;
            case enums.permises.ANULAR_RECETA.code:
              canSeeCancelApplication = true;
              break;
            case enums.permises.CREAR_RECETA.code:
              canCreateReceta = true;
              this.createOptions.push({ id: 1, title: "Medicamentos" });
              break;
            default:
              break;
          }
        });
      }
      return {
        canSeeHistory,
        canSeeLog,
        canSeeDispensarReceta,
        canSeeCancelApplication,
        canCreateReceta,
      };
    },
  },
  watch: {
    inputAfiliado(val) {
      if ((val?.trim() && val?.trim().length >= 3) || this.selectedAfiliado) {
        this.findAfiliado();
      } else {
        this.afiliadosNoDataText =
          enums.messages.ENTER_MINIMUM_CARACTERS_SEARCH;
        this.afiliados = [];
      }
    },
  },
  created() {
    this.loadView();
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
    });
  },
  methods: {
    ...mapActions({
      getEmisores: "taxonomy/getEmisores",
      getFinanciadores: "taxonomy/getFinanciadores",
      getSolicitudes: "solicitud/getSolicitudes",
      getFiltersByUser: "solicitud/getFiltersByUser",
      getEstados: "taxonomy/getEstados",
      printSolicitud: "solicitud/printReceta",
      copiarReceta: "solicitud/copiarReceta",
      setAlert: "user/setAlert",
      fetchAfiliadosSolicitudesByQuery:
        "taxonomy/fetchAfiliadosSolicitudesByQuery",
    }),
    selectFechaDesde(event) {
      this.selectedFechaDesde = event.dateString;
      this.fechaDesde = event.date;
    },
    focusComponent(refName, timeout) {
      focusComponent(refName, timeout);
    },
    selectFechaHasta(event) {
      this.selectedFechaHasta = event.dateString;
      this.fechaHasta = event.date;
    },
    selectFechaDispensaDesde(event) {
      this.selectedFechaDispensaDesde = event.dateString;
      this.fechaDispensaDesde = event.date;
    },
    selectFechaDispensaHasta(event) {
      this.selectedFechaDispensaHasta = event.dateString;
      this.fechaDispensaHasta = event.date;
    },
    async imprimirSolicitud(solicitudId) {
      this.loadingPrint = true;
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
      });
      await this.printSolicitud(solicitudId);
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
      });
      this.loadingPrint = false;
    },
    async loadView() {
      this.emisores = await this.getEmisores();
      this.financiadores = await this.getFinanciadores();

      this.estadosList = await this.getEstados();

      await this.filtersByUser();

      this.setDefaultDates();

      this.loadedView = true;
      focusComponent("v-text-field-nroSolicitud", 1);
    },
    async filtersByUser() {
      try {
        const filters = await this.getFiltersByUser();
        if (filters && filters.esAdmin) this.esAdmin = true;
        if (filters && filters.emisorId !== null) {
          this.isUserEmisor = true;
          this.selectedEmisor = this.emisores.find(
            (emisor) => emisor.id === filters.emisorId
          );
          if (
            filters.financiadoresxEmisor &&
            filters.financiadoresxEmisor.length > 0
          ) {
            this.financiadores = this.financiadores.filter((financiador) =>
              filters.financiadoresxEmisor.find((id) => id === financiador.id)
            );
          }
        } else if (filters && filters.financiadorId !== null) {
          this.isUserFinanciador = true;

          this.selectedFinanciador = this.financiadores.find(
            (financiador) => financiador.id === filters.financiadorId
          );
          if (
            filters.emisoresxFinanciador &&
            filters.emisoresxFinanciador.length > 0
          ) {
            this.emisores = this.emisores.filter((emisor) =>
              filters.emisoresxFinanciador.find((id) => id === emisor.id)
            );
          }
        } else if (filters && filters.farmaciaId !== null) {
          this.isUserFarmacia = true;
        } else if (filters && filters.preId !== null) {
          this.isUserPrestador = true;
          if (
            filters.financiadoresXPrestador &&
            filters.financiadoresXPrestador.length > 0
          ) {
            this.financiadores = this.financiadores.filter((financiador) =>
              filters.financiadoresXPrestador.find(
                (id) => id === financiador.id
              )
            );
            if (this.financiadores.length === 1)
              this.selectedFinanciador = this.financiadores[0];
          }
        }
      } catch (error) {
        console.error("Error al cargar los datos del usuario", error);
      }
    },
    async findAfiliado() {
      if (this.timerAfiliado) {
        clearTimeout(this.timerAfiliado);
        this.timerAfiliado = null;
      }
      this.timerPrestador = setTimeout(async () => {
        if (this.afiliados?.some((x) => x.title == this.inputAfiliado)) {
          return;
        } else if (!this.selectedAfiliado) {
          this.loadingAfiliados = true;
          const response = await this.fetchAfiliadosSolicitudesByQuery({
            input: this.inputAfiliado?.trim(),
          });
          this.afiliados = response;
          this.afiliadosNoDataText = enums.messages.AFILIADOS_NOT_FOUND;
          this.loadingAfiliados = false;
        }
      }, 1000);
    },
    async loadSolicitudes() {
      this.isLoadingTable = true;
      if (!this.isFormValid) {
        this.isLoadingTable = false;
        return;
      }
      this.showFilters = false;
      this.customizeFiltersApplied();
      const data = {
        emisorId: this.selectedEmisor ? this.selectedEmisor.id : null,
        financiadorId: this.selectedFinanciador
          ? this.selectedFinanciador.id
          : null,
        afiliado: this.selectedAfiliado ? this.selectedAfiliado.value : null,
        nroSolicitud: this.numeroSolicitud ? this.numeroSolicitud : null,
        estadoIds:
          this.selectedEstados.length != 0
            ? this.selectedEstados.map((x) => x.id)
            : [],
        fechaDesde: this.numeroSolicitud
          ? null
          : helperDate.convertDateFormat(this.selectedFechaDesde),
        fechaHasta: this.numeroSolicitud
          ? null
          : helperDate.convertDateFormat(this.selectedFechaHasta),
        fechaDispensaDesde:
          this.isUserFarmacia &&
          (this.numeroSolicitud == null || this.numeroSolicitud == "")
            ? helperDate.convertDateFormat(this.selectedFechaDispensaDesde)
            : null,
        fechaDispensaHasta:
          this.isUserFarmacia &&
          (this.numeroSolicitud == null || this.numeroSolicitud == "")
            ? helperDate.convertDateFormat(this.selectedFechaDispensaHasta)
            : null,
      };
      const response = await this.getSolicitudes(data);
      this.solicitudes = response;

      this.isLoadingTable = false;
      // }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.selectedEmisor) {
        this.filtersApplied.push({
          key: "emisorNom",
          label: "Emisor",
          model: this.selectedEmisor?.value,
        });
      }
      if (this.selectedFinanciador) {
        this.filtersApplied.push({
          key: "financiadorNom",
          label: "Financiador",
          model: this.selectedFinanciador?.value,
        });
      }
      if (this.selectedAfiliado) {
        this.filtersApplied.push({
          key: "afiliadoNom",
          label: "Afiliado",
          model: `${this.selectedAfiliado?.title} - ${this.selectedAfiliado?.raw.value2}`,
        });
      }
      if (this.numeroSolicitud) {
        this.filtersApplied.push({
          key: "solicitudId",
          label: "Número solicitud",
          model: this.numeroSolicitud,
        });
      }
      if (
        this.selectedEstados?.length != 0 &&
        this.selectedEstados?.length == this.estadosList?.length
      ) {
        this.filtersApplied.push({
          key: "estado",
          label: "Estado/s",
          model: "Todos",
        });
      } else if (this.selectedEstados?.length > 0) {
        this.filtersApplied.push({
          key: "estado",
          label: "Estado/s",
          model: this.selectedEstados.map((x) => x.value).join(", "),
        });
      }
      if (this.selectedFechaDesde && this.numeroSolicitud === null) {
        this.filtersApplied.push({
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.selectedFechaDesde,
        });
      }
      if (this.selectedFechaHasta && this.numeroSolicitud === null) {
        this.filtersApplied.push({
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.selectedFechaHasta,
        });
      }
      if (this.selectedFechaDispensaDesde) {
        this.filtersApplied.push({
          key: "fechaDispensaDesde",
          label: "Fecha dispensa desde",
          model: this.selectedFechaDispensaDesde,
        });
      }
      if (this.selectedFechaDispensaHasta) {
        this.filtersApplied.push({
          key: "fechaDispensaHasta",
          label: "Fecha dispensa hasta",
          model: this.selectedFechaDispensaHasta,
        });
      }
    },
    truncateText(text, maxLength = 20) {
      return (text ?? "")?.length > maxLength
        ? (text ?? "").substring(0, maxLength) + "..."
        : text;
    },
    getTextWhenFilterIsArray(currentFilter) {
      if (currentFilter.model.length > 0 && currentFilter.model.length <= 3) {
        return currentFilter.model.map((m) => {
          return m.value ? m.value : m;
        });
      }
      if (currentFilter.model.length > 3) {
        let totalFiltros = currentFilter.model.length - 3;
        let firstThree = currentFilter.model.slice(0, 3).map((f) => {
          return f.value ? f.value : f;
        });
        return `${firstThree} y ${totalFiltros} más`;
      }
    },
    clearFilters() {
      this.selectedEmisor = this.isUserEmisor ? this.selectedEmisor : null;
      this.selectedFinanciador = this.isUserFinanciador
        ? this.selectedFinanciador
        : null;
      this.inputAfiliado = null;
      this.selectedAfiliado = null;
      this.numeroSolicitud = null;
      this.selectedEstados = [];
      this.fechaDispensaDesde = null;
      this.fechaDispensaHasta = null;
      this.disabledByNroSolicitud = false;
      this.setDefaultDates();
    },
    openModalCreateSolicitud(id) {
      // Recibe por parámetro el id del array de opciones para crear una solicitud
      // Ya que el id lo ponemos nosotros, hago un case para saber que modal abrir
      switch (id) {
        case 1: // Receta electrónica
          this.modalCreateRecetaElectronica = true;
          break;

        default:
          break;
      }
    },
    isClearable(filterType) {
      if (filterType === "emisor") {
        return this.selectedEmisor !== null && !this.isUserEmisor;
      } else if (filterType === "financiador") {
        return this.selectedFinanciador !== null && !this.isUserFinanciador;
      }
      return true;
    },
    openModalStatus(item) {
      this.solicitudItem = item;
      this.isOpenModalStatus = true;
    },
    canDispensarReceta(item) {
      return (
        (item.estado === "PENDIENTE DE DISPENSA" ||
          item.estado === "DISPENSADA PARCIALMENTE" ||
          item.estado === "DISPENSADA") &&
        new Date(helperDate.convertDateFormat(item.fechaEmision)) <= new Date()
      );
    },
    canImprimirReceta({ estado }) {
      return (
        estado === "PENDIENTE DE DISPENSA" ||
        estado === "DISPENSADA PARCIALMENTE" ||
        estado === "DISPENSADA" ||
        estado === "VENCIDA"
      );
    },
    canAnularReceta(item) {
      return (
        (this.isUserPrestador && item.estado == "PENDIENTE DE DISPENSA") ||
        (this.isUserEmisor && item.estado != "ANULADA") ||
        (this.permisosSolicitudes.canSeeCancelApplication &&
          item.estado != "ANULADA" &&
          item.estado === "PENDIENTE DE DISPENSA")
      );
    },
    async copyReceta(item) {
      this.loadingCopyReceta = true;
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
      });
      try {
        const response = await this.copiarReceta(item.id);
        if (response.status === 200) {
          this.openModalCreateSolicitud(1);
          this.solicitudItem = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      this.$store.dispatch("user/changeAppLoading", {
        status: false,
        text: "",
      });
      this.loadingCopyReceta = false;
    },
    openModalReceta(item) {
      this.isOpenModalReceta = true;
      this.solicitudItem = item;
    },
    openModalVerLog(id) {
      this.verLogSolicitudDialog = true;
      this.solicitudItem = id;
    },
    customFilter(item) {
      return item.value3 || item.value1 || item.value2;
    },
    closeDialog(reload) {
      this.isOpenModalStatus = false;
      this.isOpenModalReceta = false;
      this.modalCreateRecetaElectronica = false;
      this.anularSolicitudDialog = false;
      this.verLogSolicitudDialog = false;
      this.solicitudItem = null;
      if (reload) this.loadSolicitudes();
    },
    formatDate(date) {
      return helperDate.formatDate(date);
    },
    setDefaultDates() {
      const currentDate = new Date();
      const defaultStartDate = new Date(currentDate);
      defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);

      this.fechaDesde = defaultStartDate;
      this.fechaHasta = currentDate;
    },
    exportarConsulta() {
      let result = [];
      this.solicitudes?.forEach((x) => {
        let medicamentosPreescriptos = [];
        if (
          Array.isArray(x.medicamentosPreescriptos) &&
          x.medicamentosPreescriptos.length > 0
        ) {
          medicamentosPreescriptos = x.medicamentosPreescriptos.map(
            (medicamento) => ({
              nombre: medicamento.regNom,
              cantidad: medicamento.cantidad,
            })
          );
        }

        let medicamentosPreescriptosFormatted =
          medicamentosPreescriptos.length > 0
            ? medicamentosPreescriptos
                .map((med) => `● ${med.nombre} / (${med.cantidad})`)
                .join("\n")
            : "";

        let medicamentosDispensados = [];
        if (
          Array.isArray(x.medicamentosDispensados) &&
          x.medicamentosDispensados.length > 0
        ) {
          medicamentosDispensados = x.medicamentosDispensados.map(
            (medicamento) => ({
              nombre: medicamento.regNom,
              cantidad: medicamento.cantidad,
            })
          );
        }

        let medicamentosDispensadosFormatted =
          medicamentosDispensados.length > 0
            ? medicamentosDispensados
                .map((med) => `● ${med.nombre} / (${med.cantidad})`)
                .join("\n")
            : "";

        result.push({
          ["N° solicitud"]: x.numero,
          ["Fecha creación"]: x.fechaCreacion,
          ["Fecha prescripción"]: x.fechaEmision,
          ["Emisor"]: x.emisor.value,
          ["Financiador"]: x.financiador.value,
          ["Estado"]: x.estado,
          ["Medicamentos prescriptos / (Cantidad)"]:
            medicamentosPreescriptosFormatted,
          ["Medicamentos dispensados / (Cantidad)"]:
            medicamentosDispensadosFormatted,
          ["N° afiliado"]: x.nroAfi,
          ["Afiliado"]: x.apeNomAfi,
          ["Email"]: x.afiliadoContactInfo.email,
          ["Teléfono"]: x.afiliadoContactInfo.telefono,
        });
      });

      let formato = {
        filas: result,
        hoja: "Recetas",
      };
      helpersExcel.excelExport(formato, "Consulta de solicitudes");
    },
    changeNumeroSolicitud() {
      if (this.numeroSolicitud) {
        this.disabledByNroSolicitud = true;
        this.fechaDesde =
          this.fechaHasta =
          this.selectedFechaDispensaDesde =
          this.fechaDispensaDesde =
          this.selectedFechaDispensaHasta =
          this.fechaDispensaHasta =
          this.selectedEmisor =
          this.selectedFinanciador =
          this.inputAfiliado =
          this.selectedAfiliado =
            null;
      } else {
        this.disabledByNroSolicitud = false;
        this.filtersByUser();
      }
      this.setDefaultDates();
    },
    setAfiliado(afiliado) {
      this.selectedAfiliado = afiliado;
      this.inputAfiliado = afiliado.title;
    },
    openAnularSolicitudDialog(item) {
      this.anularSolicitudDialog = true;
      this.solicitudItem = item;
    }
  },
};
</script>

<style scoped></style>
